import React from "react";

import "@fontsource/quicksand";
import "@fontsource/quicksand/600.css";
import "./App.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";

import { CategorizedProducts, Information, Product } from "types";

import { Carousel } from "react-responsive-carousel";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

import CloseIcon from "@mui/icons-material/Close";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";

function App() {
  const [open, setOpen] = React.useState(false);
  // @ts-ignore
  const [information, setInformation] = React.useState<Information[]>(window.data.information);
  // @ts-ignore
  const [products, setProducts] = React.useState<Product[]>(window.data.products);

  const categorizedProducts = React.useMemo(() => {
    return products.reduce<CategorizedProducts>((acc, product) => {
      if (acc[product.category]) {
        acc[product.category].push(product);
      } else {
        acc[product.category] = [product];
      }
      return acc;
    }, {});
  }, [products]);
  const slides = React.useMemo(() => {
    let slides = [];
    for (let i = 1; i <= 10; i++) {
      const slide = information.find((info) => info.key === `slide${i}`);
      if (slide) {
        slides.push(slide.value);
      }
    }
    return slides;
  }, [information]);
  const logo = information.find((info) => info.key === "logo")?.value;
  const facebook = information.find((info) => info.key === "facebook")?.value;
  const instagram = information.find((info) => info.key === "instagram")?.value;
  const whatsapp = information.find((info) => info.key === "whatsapp")?.value;
  const phone = information.find((info) => info.key === "phone")?.value;
  const email = information.find((info) => info.key === "email")?.value;

  const handleHomeClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    e.preventDefault();
  };

  const handleContactUsClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    setOpen(true);
  };

  return (
    <div>
      <div className="navbar" style={{ display: "block" }}>
        <Container
          maxWidth="md"
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <div className="title">
            {logo && <img src={logo} alt="" className="logo" />}
          </div>
          <div className="menu">
            <a className="menu-item" href="#" onClick={handleHomeClick}>
              Home
            </a>
            <a className="menu-item" href="#" onClick={handleContactUsClick}>
              Contact Us
            </a>
          </div>
        </Container>
      </div>
      <Carousel showThumbs={false} showStatus={false}>
        {slides.map((slide, index) => (
          <div key={index}>
            <img src={slide} alt="" />
          </div>
        ))}
      </Carousel>
      <Container maxWidth="md">
        {Object.keys(categorizedProducts).map((category) => (
          <div key={category}>
            <div className="category">{category}</div>
            <Grid container justifyContent="center">
              {categorizedProducts[category].map((product, index) => (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  key={index}
                  className="product"
                >
                  <img
                    src={product.image}
                    alt=""
                    className="product-image"
                    onClick={() => setOpen(true)}
                  />
                  <div className="product-line">
                    <div className="product-details">
                      <div className="product-name">{product.name}</div>
                      <div className="product-price">Rs. {product.price}/-</div>
                    </div>
                    <Button size="small" onClick={() => setOpen(true)}>
                      Order
                    </Button>
                  </div>
                </Grid>
              ))}
            </Grid>
          </div>
        ))}
        <footer className="footer">
          Developed by <a href="https://softvertex.dev">Softvertex</a>
        </footer>
      </Container>
      <Dialog
        onClose={() => setOpen(false)}
        aria-labelledby="customized-dialog-title"
        open={open}
        maxWidth="xs"
        fullWidth
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          Contact Us
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => setOpen(false)}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <List disablePadding>
          {facebook && (
            <ListItem disablePadding>
              <ListItemButton LinkComponent="a" href={facebook}>
                <ListItemIcon>
                  <FacebookIcon />
                </ListItemIcon>
                <ListItemText primary="Facebook" />
              </ListItemButton>
            </ListItem>
          )}
          {instagram && (
            <ListItem disablePadding>
              <ListItemButton LinkComponent="a" href={instagram}>
                <ListItemIcon>
                  <InstagramIcon />
                </ListItemIcon>
                <ListItemText primary="Instagram" />
              </ListItemButton>
            </ListItem>
          )}
          {whatsapp && (
            <ListItem disablePadding>
              <ListItemButton LinkComponent="a" href={whatsapp}>
                <ListItemIcon>
                  <WhatsAppIcon />
                </ListItemIcon>
                <ListItemText primary="WhatsApp" />
              </ListItemButton>
            </ListItem>
          )}
          {phone && (
            <ListItem disablePadding>
              <ListItemButton LinkComponent="a" href={phone}>
                <ListItemIcon>
                  <PhoneIcon />
                </ListItemIcon>
                <ListItemText primary="Phone" />
              </ListItemButton>
            </ListItem>
          )}
          {email && (
            <ListItem disablePadding>
              <ListItemButton LinkComponent="a" href={email}>
                <ListItemIcon>
                  <EmailIcon />
                </ListItemIcon>
                <ListItemText primary="Email" />
              </ListItemButton>
            </ListItem>
          )}
        </List>
      </Dialog>
    </div>
  );
}

export default App;
